import React, { useEffect, useState } from 'react';
import './index.css';
import Logo from './logoX.png';
import Menu from './Menu';
import Menu2 from './Menu2'
import { FiCheck } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { useLanguage } from '../Helpers/LanguageContext'
import ToggleLang from './ToggleLang';


const Navbar = ({
  ScrollToHome,
  ScrollToABout,
  ScrollToWhatIbring,
  ScrollToSkills,
  ScrollToProjects,
  ScrollToTimeline,
  ScrollToContact,
  isMenuClicked,
  setisMenuClicked,
  IDsingleProject, 
  isSingleProjectClicked, 
  setisSingleProjectClicked,
  hideScroll,
  sethideScroll,
  isSignatureChanged,
  setisSignatureChanged,
  isLargeScreen
}) => {

  const [text, setText] = useState("");
  const [indexXXX, setIndex] = useState(0); 
  const fullText = "kram";


  
  const { language, setLanguage } = useLanguage();

  const toggleLanguage = (lang) => {
    setisSignatureChanged(!isSignatureChanged);
    setLanguage(lang);
  };


  useEffect(() => {
    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => {
          if (prevIndex < fullText.length) {
            setText((prev) => prev + fullText[prevIndex]);
            return prevIndex + 1;
          } else {
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, 45);
  
      return () => clearInterval(interval); 
    }, 1000);  
  
    return () => clearTimeout(timeout);  
  }, [fullText]); 
  

  hideScroll?document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";


  
  return (
    <>
      <div className={(isMenuClicked) ? "Navbar activatedNavbar" :isSingleProjectClicked ? "activatedNavbar2 " : "Navbar"}>
        {
          isSingleProjectClicked ? 
            <div onClick={()=>{
                sethideScroll(false);
                setisSingleProjectClicked(false);
              }}  
              className={isSingleProjectClicked ? "logo" : "logo"}
            >
              <div className="slfghousfghusfghhusfg">
              <FiArrowLeft  color='#2d2d2d' size={isLargeScreen ? 31 : 22} className='sufowhdusdw'  />
              </div>
            </div>
          :
          <>
            <div onClick={ScrollToHome} className={isMenuClicked ? "logo hideLogo" : "logo"}>
              <div key={indexXXX} className="hihi">
                <img src={Logo} alt="Logo" />
                <span className="animated-text">{text}</span>
              </div>
            </div>
            <div className='dfshgushfgusfuguhsfu'>
              {
                !isMenuClicked && 
                <ToggleLang 
                  setisSignatureChanged={setisSignatureChanged}
                  isSignatureChanged={isSignatureChanged}
                />
              }
              <button 
                className='menuButton' 
                onClick={()=>{
                  if(isMenuClicked){
                    sethideScroll(false);
                  }
                  else{
                    sethideScroll(true);
                  }
                  setisMenuClicked(!isMenuClicked);
                }}
              >
              {
                isLargeScreen ? 
                <Menu2 isMenuClicked={isMenuClicked} />
                :
                <Menu isMenuClicked={isMenuClicked} />
              }
              </button>
            </div>
          </>
        }
      </div>
      <div className={isMenuClicked ? "popUp showpopUp" : "popUp"}>
        <div onClick={ScrollToHome} className="element">
          {language === "en" ? "Home" : "Zuhause"}
        </div>

        <div onClick={ScrollToABout} className="element">
          {language === "en" ? "Who I Am" : "Wer Bin Ich"}
        </div>

        <div onClick={ScrollToWhatIbring} className="element">
           
          {language === "en" ? "What I Bring" : "Was Ich Mitbringe"}
        </div>
        
        <div onClick={ScrollToSkills} className="element">
          
          {language === "en" ? "Capabilities" : "Fähigkeiten"}

        </div>

        
        
        <div onClick={ScrollToTimeline} className="element">
          
          {language === "en" ? "Timeline" : "Meilensteine"}

        </div>


        <div onClick={ScrollToProjects} className="element">
          
          {language === "en" ? "Showcase" : "Projekte"}

        </div>
        

        <div onClick={ScrollToContact} className="element">
          
          {language === "en" ? "Get In Touch" : "Kontakt Aufnehmen"}

        </div>

        
        <div className="element element292">
          <button onClick={() => toggleLanguage("de")}>
            {language === "de" ? (
              <div className={isLargeScreen ? 'dsnhfxuihsfdsnhfxuihsf888' : "dsnhfxuihsfdsnhfxuihsf909"} >
                <FiCheck size={isLargeScreen ? 33 : 22} color="gray" />
              </div>
            ) : (
              <div className={isLargeScreen ? 'dsnhfxuihsfdsnhfxuihsf888' : "dsnhfxuihsfdsnhfxuihsf909"} ></div>
            )}
            {language === "en" ? "German" : "Deutsch"}
          </button>
          <button onClick={() => toggleLanguage("en")}>
            {language === "en" ? (
              <div className={isLargeScreen ? 'dsnhfxuihsfdsnhfxuihsf888' : "dsnhfxuihsfdsnhfxuihsf909"} >
                <FiCheck size={isLargeScreen ? 33 : 22} color="gray" />
                
              </div>
            ) : (
              <div className={isLargeScreen ? 'dsnhfxuihsfdsnhfxuihsf888' : "dsnhfxuihsfdsnhfxuihsf909"} ></div>
            )}
            {language === "en" ? "English" : "Englisch"}
          </button>
        </div>



      </div>
    </>
  );
};

export default Navbar;
