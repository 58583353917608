import React from 'react';
import W2 from './w2.png';
import W3 from './w3.png';

const Progress = ({ isRendered }) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={isRendered ? "Progress ShowProgress" : "Progress"}>
      <button onClick={() => openInNewTab('https://www.linkedin.com/in/akram-elbasri')}>
        <img src={W2} alt="Open LinkedIn" />
      </button>
      <button onClick={() => openInNewTab('mailto:seasonedwebdev@gmail.com')}>
        <img src={W3} alt="Open Gmail" />
      </button>
    </div>
  );
};

export default Progress;
