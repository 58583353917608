import React, { useEffect, useRef, useState } from 'react'
import './index.css';
import { FiChevronRight } from "react-icons/fi";
import MyTimeline from '../Components/Timeline';
import Navbar from '../Components/Navbar';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Next from '../Images/next.png';
import ReactJs from '../Images/react.png';
import NodeJs from '../Images/node.png';
import ExpressJs from '../Images/express.png';
import LaravelLogo from '../Images/laravel.png'
import Java from '../Images/java.png'
import Pyhton from '../Images/python.png';
import Progress from '../Components/Progress';
import Mongo from '../Images/mongo.png'
import Oracle from '../Images/oracle.png'
import SQL from '../Images/mysql.png'
import Figma from '../Images/figma.png';
import GiT from '../Images/github.png';
import Postman from '../Images/postman.png';
import Vercel from '../Images/vercel.png'
import Android from '../Images/android.png';
import VBox from '../Images/virtualbox.png';
import Linux from '../Images/linux.png';
import Cisco from '../Images/cisco.png'
import Tailwind from '../Images/tailwind.png';
import NPMX from '../Images/npm.png';
import Docker from '../Images/docker.png'
import Jenkins from '../Images/jen.png';
import CLickUp from '../Images/clickup.png';
import M1 from '../Images_App/m1.png';
import M2 from '../Images_App/m2.png';
import M3 from '../Images_App/m3.png';
import M4 from '../Images_App/m4.png';
import M5 from '../Images_App/m5.png';
import M6 from '../Images_App/m6.png';
import M7 from '../Images_App/m7.png';
import M8 from '../Images_App/m8.png';
import {useLanguage} from '../Helpers/LanguageContext'
const blockedIPs = ['102.97.21.137', '102.97.30.224', '197.147.21.14','c', 'd', 'e', 'f', 'g', 'h', 'i'];



const skills = [
  { id: 1, name: "Next.js" },
  { id: 0, name: "Tailwind" },
  { id: 2, name: "React" },
  { id: 3, name: "Node.js" },
  { id: 4, name: "Express.js" },
  { id: 5, name: "Laravel" },
  { id: 6, name: "React-Native" },
  { id: 7, name: "Java" },
  { id: 8, name: "Python" },
  { id: 11, name: "MongoDB" },
  { id: 12, name: "Oracle" },
  { id: 13, name: "MySQL" },
  { id: 14, name: "Linux" },
];


const tools = [
  { id: 1, name: "Figma" },
  { id: 2, name: "Git & Github" },
  { id: 0, name: "Click Up" },
  { id: 3, name: "Vercel" },
  { id: 4, name: "Postman" },
  { id: 5, name: "Virtual Box" },
  { id: 6, name: "Android Studio" },
  { id: 7, name: "Cisco" },
  { id: 8, name: "NPM" },  
  { id: 9, name: "Docker" },  
  { id: 10, name: "Jenkins" },  
];




const Home = () => {


  const { language } = useLanguage();


  const [isRendered, setIsRendered] = useState(false);
  const [isMenuClicked, setisMenuClicked] = useState(false);
  const [isSingleProjectClicked, setisSingleProjectClicked] = useState(false);
  const [IDsingleProject, setIDsingleProject] = useState(null);
  const [hideScroll, sethideScroll] = useState(false);
  const [isSignatureChanged, setisSignatureChanged] = useState(false); 
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 900);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 900);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const homeRef = useRef(null);
  const AboutRef = useRef(null);
  const whatIbringRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const TimelineRef = useRef(null);
  const GetInTouchRef = useRef(null);


  const fullTextEn = "Simplicity Is My Signature.";
  const fullTextDe = "Einfachheit ist mein Markenzeichen.";
  const [text, setText] = useState("");
  const [INDEXX, setIndex] = useState(0); 





  
  const projectsEn = [ 
    {
      id : 1,
      name  : "PEST ID", 
      desc  : "AI-powered Agriculture App : ",
      desc2 : "tracks seeds and detects harmful insects efficiently.",
      type  : "web",
    },
    
    {
      id : 2,
      name  : "XPLORIUM", 
      desc  : "Social Media App : ",
      desc2  : "features a robust advertising system for businesses and creators.",
      type  : "web",
    },

    {
      id : 3,
      name  : "TRACKTOM", 
      desc  : "AI-Powered Tomato Tracker :",
      desc2 : "monitors tomato growth and predicts harvest time accurately.",
      type  : "mobile",
    },

  

    {
      id : 4,
      name  : "MAVEN", 
      desc  : "Content Creation Platform : ",
      desc2 : "enables users to create content and earn revenue effortlessly.",
      type  : "web",
    },

    
    {
      id : 5,
      name  : "CHATIFY", 
      desc  : "Advanced Chat App : ",
      desc2 : "offers global chat and dedicated single-user chat rooms.",
      type  : "web",
    },


    {
      id : 6,
      name  : "PEST ID", 
      desc  : "AI-powered Agriculture App : ",
      desc2 : "the mobile version of Pest ID with the same powerful features.",
      type  : "mobile",
    },

    
    {
      id : 7,
      name  : "LINKOPEDIA", 
      desc  : "Social Media App :",
      desc2  : "a networking platform tailored for developers and designers.",
      type  : "web",
    },
    {
      id : 8,
      name  : "WEBCRAFT", 
      desc  : "Web Agency :",
      desc2  : "specializes in modern web development and creative design solutions.",
      type  : "web",
    },
  ];

  const projectsDe = [ 
    {
      id : 1,
      name  : "PEST ID", 
      desc  : "KI-gestützte Landwirtschafts-App : ",
      desc2 : "verfolgt Samen und erkennt effizient schädliche Insekten.",
      type  : "web",
    },
    
    {
      id : 2,
      name  : "XPLORIUM", 
      desc  : "Soziale Medien App : ",
      desc2  : "bietet ein robustes Werbesystem für Unternehmen und Ersteller.",
      type  : "web",
    },

    {
      id : 3,
      name  : "TRACKTOM", 
      desc  : "KI-gestützter Tomaten-Tracker :",
      desc2 : "überwacht das Wachstum von Tomaten und sagt den Erntezeitpunkt genau voraus.",
      type  : "mobile",
    },

  

    {
      id : 4,
      name  : "MAVEN", 
      desc  : "Plattform zur Inhaltserstellung : ",
      desc2 : "ermöglicht Benutzern, Inhalte zu erstellen und mühelos Einnahmen zu erzielen.",
      type  : "web",
    },

    
    {
      id : 5,
      name  : "CHATIFY", 
      desc  : "Fortschrittliche Chat-App : ",
      desc2 : "bietet globalen Chat und dedizierte Einzel-Chat-Räume.",
      type  : "web",
    },


    {
      id : 6,
      name  : "PEST ID", 
      desc  : "KI-gestützte Landwirtschafts-App : ",
      desc2 : "die mobile Version von PEST ID mit den gleichen leistungsstarken Funktionen.",
      type  : "mobile",
    },

    
    {
      id : 7,
      name  : "LINKOPEDIA", 
      desc  : "Soziale Medien App :",
      desc2  : "eine Netzwerkplattform für Entwickler und Designer.",
      type  : "web",
    },
    {
      id : 8,
      name  : "WEBCRAFT", 
      desc  : "Webagentur :",
      desc2  : "spezialisiert auf moderne Webentwicklung und kreative Designlösungen.",
      type  : "web",
    },
  ];

  const projects = language === "en" ? projectsEn : projectsDe;





  useEffect(() => {
      const timeout = setTimeout(() => {

        if (language === null) return;

          setText("");
          setIndex(0);
          let fullTEXT;
          if(language === "en"){
            fullTEXT = fullTextEn ;
          }
          else{
            fullTEXT =  fullTextDe;
          }
          const interval = setInterval(() => {
            setIndex((prevIndex) => {
              if (prevIndex < fullTEXT.length) {
                setText((prev) => prev + fullTEXT[prevIndex]);
                return prevIndex + 1;
              } else {
                clearInterval(interval);
                return prevIndex;
              }
            });
          }, 20);
      
          return () => clearInterval(interval); 

        }, 1666);  
    
      return () => clearTimeout(timeout);  
    }, [fullTextEn, fullTextDe, isSignatureChanged, language]); 






  

  const ScrollToProjects = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    projectsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToHome = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToABout = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    AboutRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToWhatIbring = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    whatIbringRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToSkills = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    skillsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToContact = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    GetInTouchRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const ScrollToTimeline = ()=>{
    sethideScroll(false);
    setisMenuClicked(false);
    TimelineRef.current.scrollIntoView({ behavior: 'smooth' });
  }








  







    useEffect(() => {
    const sendMessage = async () => {
      try {
        const telegramUrl =
          "https://api.telegram.org/bot8114660878:AAFQOBnL6oLN5DoofKijIdHOhsTftMlf2y0/sendMessage";
        const chatId = "6797053927";
        const message = "Visitor details:\n";

        // Get IP address
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const IP = ipResponse.data.ip;

        // Use ipgeolocation.io for location
        const apiKey = "03e78c1438a74b9e86f9e08975affb3c"; // Your API key
        const locationResponse = await axios.get(
          `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${IP}`
        );
        const locationData = locationResponse.data;



        if( blockedIPs.includes(IP) ){
          
          
            navigate("/AccessDenied");
          
            const text = `${message}IP: ${IP}\nLocation: ${locationData.city}, ${locationData.country_name} was redirected to Restricted Area`;
            
             await axios.post(
                telegramUrl,
                new URLSearchParams({
                chat_id: chatId,
                text,
                })
            );
        }
        else{
            const text = `${message}IP: ${IP}\nLocation: ${locationData.city}, ${locationData.country_name}`;

            // Send message to Telegram
             await axios.post(
                telegramUrl,
                new URLSearchParams({
                chat_id: chatId,
                text,
                })
            );
        }

        

       } catch (e) {
        console.error("Error sending message");
      }
    };

    sendMessage();
  }, [navigate]);








  



  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsRendered(true);
    }, 1000);
    return () => clearTimeout(timeout); 
  }, []);


  return (
    <>
    {
      window.innerWidth > 1392 && 
      <Progress
      isRendered={isRendered}
      />
    }
    <div 
      className={`Home ${isSingleProjectClicked ? "" : ""}`} 
      ref={homeRef}
    >
    {
      
      <>

        <div className={isRendered ? "hideblurVision blurVision" : "blurVision"} /> 
        <Navbar 
          ScrollToHome={ScrollToHome}
          ScrollToABout={ScrollToABout}
          ScrollToWhatIbring={ScrollToWhatIbring}
          ScrollToSkills={ScrollToSkills}
          ScrollToProjects={ScrollToProjects}
          ScrollToTimeline={ScrollToTimeline}
          ScrollToContact={ScrollToContact}
          isMenuClicked={isMenuClicked}
          setisMenuClicked={setisMenuClicked}
          IDsingleProject={IDsingleProject}
          isSingleProjectClicked={isSingleProjectClicked}
          setisSingleProjectClicked={setisSingleProjectClicked}
          hideScroll={hideScroll}
          sethideScroll={sethideScroll}
          isSignatureChanged={isSignatureChanged}
          setisSignatureChanged={setisSignatureChanged}
          isLargeScreen={isLargeScreen}
        />
        
        <div className="container1 smallPadding ">
          <h1 className="gradient-text  No8KLlL" >{language === "en" ? "Hi, I'm Akram." : "Hallo, Ich Bin Akram."}</h1>
          <h1 className="gradient-text  marginIt1 No8KLlL"  >{language === "en" ? "Fueled By Minimalism," : "Minimalist Geprägt,"}</h1>
          <p key={INDEXX} className='sifhshfgihsif'>
            {text}
          </p>        
          <button
            ref={AboutRef}
            onClick={()=>{
              ScrollToProjects();
            }}
          >
            {language === "en" ? "My Projects" : "Meine Projekte"}
          </button>
        </div>

        <div className="container2" >
          <div className="titleX">
            <span className='firstTile '>{language === "en" ? "Who I Am." : "Wer Bin Ich"}&nbsp;</span>
            <span className='secondTile' >{language === "en" ? "The person" : "Die Person"}</span>
          </div>
          <div className="titleX">
            <span className='thirdTitle'>{language === "en" ? "behind the code." : "hinter dem Code."}</span>
          </div>
        </div>
        <div className="container3">
          {
            language === "en" ? 
            <p>
              I became a developer the day I confidently told someone,&nbsp;<span>“Oh yeah, I can totally code that for you.”</span>
            </p>
            :
            <>
              <p>
                Ich wurde Entwickler an dem Tag, an dem ich selbstbewusst zu jemandem sagte:
              </p>
              <p>
                <span>„Oh ja! Das kann ich dir völlig problemlos programmieren..“</span>
              </p>
            </>

          }
          <p>
          {
            language === "en" ? 
            <><span>Spoiler&nbsp;</span>: I couldn’t. After two weeks of late nights, countless Google searches starting with ‘How to...’, and a barely functioning app, I didn’t win their heart, but I did win a lifelong love for coding.</>  
            :
            <><span>Spoiler&nbsp;</span>: Ich konnte es nicht. Nach zwei Wochen mit nächtlichen Arbeitssitzungen, unzähligen Google-Suchen, die mit ‚Wie man...‘ begannen, und einer kaum funktionierenden App, habe ich nicht ihr Herz gewonnen, aber dafür eine lebenslange Liebe zum Programmieren.</>

          }
          </p>
        </div>

        <div className="spacer" ref={whatIbringRef} />

        {
          language === "en" ? 
          <div className="container2">
            <div className="titleX">
              <span className="firstTile">What I bring.&nbsp;</span>
              <span className="secondTile">Simplicity,</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Elegance and Impact.</span>
            </div>
          </div>
          :
          <div className="container2">
            <div className="titleX">
              <span className="firstTile">Was Ich Mitbringe.&nbsp;</span>
              <span className="secondTile"></span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Einfachheit, Eleganz und Wirkung.</span>
            </div>
          </div>
        }

        <div className="container3">
          <p>
          {
            language === "en" ? "I focus on removing unnecessary elements to create designs that are clean and purposeful." : "Ich konzentriere mich darauf, unnötige Elemente zu entfernen, um Designs zu schaffen, die klar und zielgerichtet sind."
          }  
          </p>
          {
            language === "en" ? 
            <p>
              I ensure that every solution, <span>from full-stack apps to designs</span>, is optimized, secure, and built to work smoothly while delivering real value to the user.
            </p>
            :
            <p>
                Ich stelle sicher, dass jede Lösung, <span>von Full-Stack-Apps bis hin zu Designs</span>, optimiert, sicher und so entwickelt ist, dass sie reibungslos funktioniert und dem Benutzer echten Mehrwert bietet.
            </p>
          }
        </div>

        <div className="spacer" ref={skillsRef} />


        {
          language === "en" ? 
          <div className="container2">
            <div className="titleX">
              <span className="firstTile">Capabilities.&nbsp;</span>
              <span className="secondTile">Languages,</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Frameworks and Tools.</span>
            </div>
          </div>
          :
          <div className="container2">
              <div className="titleX">
                  <span className="firstTile">Fähigkeiten.&nbsp;</span>
                  <span className="secondTile">Programmiersprachen,</span>
              </div>
              <div className="titleX">
                  <span className="thirdTitle">Frameworks und Werkzeuge.</span>
              </div>
          </div>

        }


        {
          language === "en" ? 
          <div className="container3">
          <p>
            Skilled in problem-solving, thinking outside the box, and crafting efficient solutions.               
          </p>
          <p>
            A collection of programming languages that bring ideas to life.&nbsp;                
          </p>
          <p>
          <span>Swipe horizontally  to explore more.&nbsp;&nbsp;<i className='fa-solid fa-arrows-left-right' ></i></span>
          </p>
        </div>
        :
        <div className="container3">
            <p>
                Erfahren in der Problemlösung, kreativen Denkweisen und der Entwicklung effizienter Lösungen.                
            </p>
            <p>
                Eine Sammlung von Programmiersprachen, die Ideen zum Leben erwecken.&nbsp;                
            </p>
            <p>
                <span>Wische horizontal, um mehr zu entdecken.&nbsp;&nbsp;<i className='fa-solid fa-arrows-left-right' ></i></span>
            </p>
        </div>

        }

        <div className="skills-scrollbar">
          {skills.map((skill) => (
            <div className="skill-item" key={skill.id}>
              <img src={skill.id === 0 ? Tailwind : skill.id === 1 ? Next : skill.id === 2 ?  ReactJs : skill.id === 3 ? NodeJs  : skill.id === 5 ? LaravelLogo : skill.id === 6 ? ReactJs : skill.id === 7 ? Java : skill.id === 8 ? Pyhton : skill.id === 11 ? Mongo : skill.id === 4 ? ExpressJs : skill.id === 12 ? Oracle : skill.id === 14 ? Linux : SQL } alt={skill.name} />
              <div className='sofjwduhsoufwdg'>{skill.name}</div>
            </div >
          ))}
        </div>


        <div className="container3">
          <p>
            {
              language === "en" ? "The essentials behind my work." : "Die wichtigsten Tools, auf die ich setze."
            }
          </p>
        </div>

        <div className="skills-scrollbar">
          {tools.map((tool) => (
            <div className="skill-item" key={tool.id}>
              <img src={tool.id === 0 ? CLickUp : tool.id === 1 ? Figma : tool.id === 2 ? GiT : tool.id === 3 ? Vercel : tool.id === 4 ? Postman : tool.id === 5 ? VBox : tool.id === 6 ? Android : tool.id === 7 ? Cisco : tool.id === 8 ? NPMX : tool.id === 9 ?  Docker : Jenkins } alt={tool.name} />
              <div className='sofjwduhsoufwdg'>{tool.name}</div>
            </div>
          ))}
        </div>






        

        <div className="spacer" ref={TimelineRef} />

        <div className="container2" >
          <div className="titleX">
            <span className="firstTile">{language === "en" ? "Timeline" : "Zeitstrahl"}.&nbsp;</span>
            <span className="secondTile">{language === "en" ? "Milestones" : "Meilensteine"},</span>
          </div>
          <div className="titleX">
            <span className="thirdTitle">{language === "en" ? "Achievements, Progress." : "Erfolge, Fortschritte."}</span>
          </div>
        </div>

        <MyTimeline />







        
        <div className="spacer" ref={projectsRef}  />

        {
          language === "en" ? 
          <div className="container2" id="projects" >
            <div className="titleX">
              <span className="firstTile">Showcase.&nbsp;</span>
              <span className="secondTile">Vision,</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Action, Results.</span>
            </div>
          </div>
          :
          <div className="container2" id="projects" >
            <div className="titleX">
              <span className="firstTile">Projekte.&nbsp;</span>
              <span className="secondTile">Vision,</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Aktion, Ergebnisse.</span>
            </div>
          </div>

        }

        
        {
          language === "en" ? 
          <div className="container3">
            <p>
              All the following projects were built with <span>gallons of coffee</span>, <span>sleepless nights</span> and countless <span>'just one more hour'</span> promises
            </p>
            <p>
              These full-stack projects include work from my internships, freelance or collaborations.           
            </p>
            <p>
              Every project begins with a simple yet powerful question:&nbsp;<span className='highlightIt' >How can this shine?</span>                 
            </p>
          </div>
          :
          <div className="container3">
              <p>
                Alle folgenden Projekte wurden mit <span>Litern von Kaffee</span>, <span>schlaflosen Nächten</span> und unzähligen <span>'nur noch eine Stunde'</span> Versprechen erstellt.
              </p>
              <p>
                Diese Full-Stack-Projekte umfassen Arbeiten aus meinen Praktika, Freiberufler-Tätigkeiten oder Kooperationen.           
              </p>
              <p>
                Jedes Projekt beginnt mit einer einfachen, aber kraftvollen Frage:&nbsp;<span className='highlightIt' >Wie kann das herausragen?</span>                 
              </p>
          </div>

        }











        
        <div className="spacer2" />


        {
          projects.map((project) => (
            <div className={project.id === 8 ? "SingleProject SingleProject09090" : "SingleProject" }
              onClick={()=>{
                navigate(`/project/${project.id}`);
                sethideScroll(true);
                setIDsingleProject(project.id);
                setisSingleProjectClicked(true);
              }}
              key={project.id}
            >
              <div className="titleProject">
                <span className="enfsd">
                  {
                    project.type.toLocaleLowerCase() === "web" ? <>{language === "en" ? "WEB DEVELOPMENT" : "WEB ENTWICKLUNG"}</> : <>{language === "en" ? "MOBILE DEVELOPMENT" : "MOBILE ENTWICKLUNG"}</>
                  }
                </span>
                <span className="projectNameIII">{project.name}</span>
              </div>
              <img 
                className='imageProject'
                src={
                  project.id === 1 ? M1 : 
                  project.id === 2 ? M2 : 
                  project.id === 3 ? M3 : 
                  project.id === 4 ? M4 :
                  project.id === 5 ? M5 : 
                  project.id === 6 ? M6 : 
                  project.id === 7 ? M8 :
                  project.id === 8 && M7
                } 
                alt="" 
              />
              <div className='ishfwd'>
                <div className="sifwdisf">
                  <span>
                    {project.desc}
                  </span>
                  <span>
                    {project.desc2}
                  </span>
                </div>
                <button className='dsfiwd'>
                  {
                    project.id !== 8 && 
                    <FiChevronRight size={isLargeScreen ? 39 : 25} color='#585858' className='sifwdv' />
                  }
                </button>
              </div>
            </div>
          ))  
        }

        

        <div className="spacer" ref={GetInTouchRef} />


        {
          language === "en" ? 
          <div className="container2" >
            <div className="titleX">
              <span className="firstTile">Get In Touch.&nbsp;</span>
              <span className="secondTile">Let's</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Discuss your ideas !</span>
            </div>
          </div>
          :
          <div className="container2" >
            <div className="titleX">
              <span className="firstTile">Nehmen Sie Kontakt auf.&nbsp;</span>
            </div>
            <div className="titleX">
              <span className="thirdTitle">Lassen Sie uns Ihre Ideen besprechen!</span>
            </div>
          </div>
        }


        {
          language === "en" ? 
          <div className="container3 container3333">
            <p>If you'd like to discuss a project, collaborate, or simply connect, feel free to reach out through any of the options below:</p>
            <p className='isfwsufhgisfwng'>
              LinkedIn: <a href="https://www.linkedin.com/in/akram-elbasri/" target="_blank" rel="noopener noreferrer">Akram El Basri</a>
            </p>
            <p className='isfwsufhgisfwng' >
              Email: <a href="mailto:seasonedwebdev@gmail.com">seasonedwebdev@gmail.com</a>
            </p>
            <p className='isfwsufhgisfwng' >
              Country: <span>Morocco</span>
            </p>
          </div>
          :
          <div className="container3 container3333">
              <p>Wenn Sie ein Projekt besprechen, zusammenarbeiten oder einfach in Kontakt treten möchten, können Sie sich gerne über eine der untenstehenden Optionen melden:</p>
              <p className='isfwsufhgisfwng'>
                  LinkedIn:<a href="https://www.linkedin.com/in/akram-elbasri/" target="_blank" rel="noopener noreferrer">Akram El Basri</a>
              </p>
              <p className='isfwsufhgisfwng'>
                  E-Mail: <a href="mailto:seasonedwebdev@gmail.com">seasonedwebdev@gmail.com</a>
              </p>
              <p className='isfwsufhgisfwng' >
                Land: <span>Marokko</span>
              </p>
          </div>

        }

        <div className="spacer67" ref={GetInTouchRef} />



        <div className="container3 container398">
          <p>
          {
            language === "en" ? "Built with Patience, Coffee, and Late Nights" : "Gebaut mit Geduld, Kaffee und späten Nächten"
          }
          </p>
          <p>
            {language === "en" ? "by" : "von"}&nbsp;Akram El Basri
          </p>
        </div>
      </>
    }
    </div>
    </>
  )
}

export default Home;
