import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './Screens/index.css';
import './Components/index.css';
import Home from './Screens/Home';
import { LanguageProvider } from "./Helpers/LanguageContext";
import SingleProjectPage from './Screens/SingleProjectPage';
import AccessDenied from './Screens/AccessDenied';


function App() {

  //hzdhf

  return (
    <div className="App">
      <LanguageProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/AccessDenied' element={<AccessDenied />} />
            <Route path='/project/:id' element={<SingleProjectPage/>} />
            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </div>
  );
}

export default App;
