import React, { useEffect, useState } from 'react';
import './index.css';
import { useLanguage } from '../Helpers/LanguageContext';



const ToggleLang = ({isSignatureChanged, setisSignatureChanged}) => {

  const { language, setLanguage } = useLanguage();

  const [Lang, setLang] = useState(null);
  
    const toggleLanguage = (lang) => {
      setisSignatureChanged(!isSignatureChanged);
      setLanguage(lang);
    };

    useEffect(()=>{
      if(language === "en"){
        setLang("en");
      }
      else{
        setLang('de')
      }
    },[language]);
  

  return (
    <>
    {
      Lang && 
      <div className="toggle-container" onClick={()=>{
        if(Lang){
          if(Lang === "en"){
            toggleLanguage("de");
          }
          else{
            toggleLanguage("en");
          }
        }
      }}>
        <div className={`toggle-switch ${language === 'de' ? 'active' : ''}`}>
          <span className="toggle-label">{language === 'en' ? 'EN' : 'DE'}</span>
        </div>
      </div>
    }
    </>
  );
};

export default ToggleLang;
