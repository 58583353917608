import React from 'react'

const AccessDenied = () => {

  return(
    <div className=""  >
      Stop browsing! My portfolio isn’t a museum. Got something to say? Contact me though: seasonedwebdev@gmail.com
    </div>
  );

}

export default AccessDenied;
