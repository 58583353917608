import React, { useState, useEffect } from 'react';
import { useLanguage } from '../Helpers/LanguageContext';

const ProjectGallery = ({ IDsingleProject }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useLanguage();



  const fetchImages = async (projectId) => {
    try {
      const imagesData = await import(`./images/project-${projectId}.json`);
      const resolvedImages = imagesData.default.map((filename) =>
        require(`./images/${filename}`)
      );
      setImages(resolvedImages);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setTimeout(()=>{
        setIsLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (IDsingleProject) {
      // Clear images immediately to avoid flicker
      setImages([]);
      setIsLoading(true);

      // Fetch new images
      fetchImages(IDsingleProject);
    }
  }, [IDsingleProject]);

 

  const projectNames = {
    en: {
      1: "PEST ID",
      2: "XPLORIUM",
      3: "TRACKTOM",
      4: "MAVEN",
      5: "CHATIFY",
      6: "PEST ID",
      7: "LINKOPEDIA",
      8: "WEBCRAFT",
    },
    de: {
      1: "PEST ID",
      2: "XPLORIUM",
      3: "TRACKTOM",
      4: "MAVEN",
      5: "CHATIFY",
      6: "PEST ID",
      7: "LINKOPEDIA",
      8: "WEBCRAFT",
    },
  };

  const projectDescriptions = {
    en: {
      1: "AI-powered App that tracks seeds and detects harmful insects efficiently.",
      2: "App that features a robust advertising system for businesses and creators.",
      3: "AI-powered App that monitors tomato growth and predicts harvest time accurately.",
      4: "App that enables users to create content and earn revenue effortlessly.",
      5: "App that offers global chat and dedicated single-user chat rooms.",
      6: "AI-powered App that tracks seeds and detects harmful insects efficiently.",
      7: "App that a networking platform tailored for developers and designers.",
      8: "App that specializes in modern web development and creative design solutions.",
    },
    de: {
      1: "KI-App, die Samen verfolgt und schädliche Insekten effizient erkennt.",
      2: "App mit robustem Werbesystem für Unternehmen und Ersteller.",
      3: "KI-App, die das Wachstum von Tomaten überwacht und die Erntezeit genau vorhersagt.",
      4: "App, die es Benutzern ermöglicht, Inhalte zu erstellen und mühelos Einnahmen zu erzielen.",
      5: "App mit globalem Chat und dedizierten Einzel-Chat-Räumen.",
      6: "KI-App, die Samen verfolgt und schädliche Insekten effizient erkennt.",
      7: "App, die eine Netzwerkplattform für Entwickler und Designer bietet.",
      8: "App, die sich auf moderne Webentwicklung und kreative Designlösungen spezialisiert.",
    },
  };

  const projectCategories = {
    en: "Project Category:",
    de: "Projektkategorie:",
  };

  const techStackLabel = {
    en: "Tech Stack:",
    de: "Technologie-Stack:",
  };

  const techStacks = {
    1: "AI / React.js / Laravel / Python / MySQL",
    2: "Websockets / API EdenAI / React.js / Node.js / Express.js / MongoDB",
    3: "AI / React-Native / Laravel / MySQL",
    4: "React.js / Node.js / Express.js / MongoDB",
    5: "WebSockets / Next.js / Node.js / Express.js / MongoDB",
    6: "AI / React-Native / Laravel / Pyhton / MySQL",
    7: "React.js / Node.js / Expres.js / MongoDB",
    8: "WebSockets / React.js / Node.js / Express.js / MongoDB",
  };

  return (
    <div className="gallery-container">
      <span className="spanishGuitar">
        {projectNames[language]?.[IDsingleProject] || "Unknown Project"}
      </span>

      <div className="techonlogiesUsed">
        
        

        <span className="ufsuogfsufsuogfs">
          {projectDescriptions[language]?.[IDsingleProject] || "No description available."}
        </span>

        <span className="ufsuogfsufsuogfs ufsuogfsufsuogfs2">
          <span>{projectCategories[language]}&nbsp;</span>
          <span>{parseInt(IDsingleProject)  === 3 || parseInt(IDsingleProject)  === 6 ? "Full-Stack Mobile Application" : "Full-Stack Web Application"}</span>
        </span>

        <div className="ufsuogfsufsuogfs ufsuogfsufsuogfs2 ufsuogfsufsuogfs2999">
          <span>{techStackLabel[language]}&nbsp;</span>
          <span>{techStacks[IDsingleProject]}</span>
        </div>
        
      </div>

      {isLoading ? <div className='ehsrigsfighshfig jofsjsfgnosfojsofgojsf'>{language === "en" ? "Loading images..." : "Bilder werden geladen..."}</div>
      :
        <div className="image-gallery">
          {images.map((src, index) => (
            <div key={index} className={parseInt(IDsingleProject) === 3 ? "image-wrapper image-wrapper2" : parseInt(IDsingleProject)  === 6 ? "image-wrapper image-wrapper2" : "image-wrapper"}>
              <img
                src={src}
                alt={`Project ${IDsingleProject}`}
                className={parseInt(IDsingleProject)  === 3 ? "dynamic-image2" :  parseInt(IDsingleProject)  === 6 ? "dynamic-image2" : "dynamic-image"}
              /> 
            </div>
          ))}
        </div>
      }

    
    </div>
  );
};

export default ProjectGallery;
