import React from 'react';

const Menu2 = ({ isMenuClicked }) => {
  return (
    <div>
      {
        isMenuClicked ? 
        <i className='fa-solid fa-xmark' ></i>
        :
        <i className='fa-solid fa-bars' ></i>
      }
    </div>
  );
};

export default Menu2;
