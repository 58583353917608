import React, {useState, useEffect} from 'react'

import { useParams } from 'react-router-dom'
import ProjectSingle from '../Components/ProjectSingle';



const SingleProjectPage = () => {



  


    const [ID, setId] = useState(null);
    const params = useParams();
    

    useEffect(()=>{
        if(params){
            setId(params.id);
        }
    }, [params]);

  return (
    <div 
        className='SingleProjectPage'
    >
      
      {
          ID !== null && 
          <ProjectSingle 
            IDsingleProject={ID}
          />
      }
    </div>
  )
}

export default SingleProjectPage
