import React from 'react';

const Menu = ({ isMenuClicked }) => {
  return (
    <div className={isMenuClicked ? "menu-icon active" : "menu-icon"}>
      <span className="bar1"></span>
      <span className="bar2"></span>
      <span className="bar3"></span>
    </div>
  );
};

export default Menu;
