import React, {useRef, useEffect} from 'react'
import ProjectGallery from './ProjectGallery';
import scrollToTop from './ScrollToTop';
import {useLanguage} from '../Helpers/LanguageContext'
import { useNavigate } from 'react-router-dom'



const ProjectSingle = ({
  IDsingleProject, 
}) => {
  
    const containerRef2 = useRef(null);
  

  useEffect(() => {
      if (containerRef2.current) {
        scrollToTop(containerRef2.current);
      }
    }, [IDsingleProject]);

const navigate = useNavigate();
        const { language } = useLanguage();
    

  
  return (
    <div className="popUpOfSingleProject showpopUpOfSingleProject" ref={containerRef2}>
      <div className="NAVBARxoFpROJECTS"
        onClick={()=>{
          navigate('/');
        }} 
      >
        <div>
          <i className='fa-solid fa-chevron-left'></i>
          &nbsp;&nbsp;
          <span>
            {
              language === "en" ? "Return Back" : "Kehre zurück"
            }
          </span>
        </div>
      </div>
      <ProjectGallery 
        IDsingleProject={IDsingleProject} 
      />
    </div>
  )
}

export default ProjectSingle;
